import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectWithOther"];

  connect() {
    console.log(this.selectWithOtherTargets);
    this.selectWithOtherTargets.forEach((target) => {
      this.applyOtherConditional(target);
    });
  }

  toggleOther(e) {
    const { target } = e;
    this.applyOtherConditional(target);
  }

  applyOtherConditional(target) {
    this.applyConditional(target, target.value === "other");
  }

  applyConditional(target, condition) {
    const { conditionalId } = target.dataset;
    const conditional = document.getElementById(conditionalId);

    if (!conditional) return;

    const conditionalInputs = conditional.querySelectorAll(
      "input, select, textarea"
    );

    if (condition) {
      conditional.style.display = "flex";
      conditionalInputs.forEach((i) => (i.disabled = false));
    } else {
      conditional.style.display = "none";
      conditionalInputs.forEach((i) => (i.disabled = true));
    }
  }
}
